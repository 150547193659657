<template>
  <b-container>
    <add-weight-station-registration-modal
      ref="AddWeightStationRegistrationModal"
    />
    <confirm-modal ref="simulationConfirmModal" />

    <b-card no-body>
      <div class="m-2">
        <b-button
          variant="secondary"
          size="sm"
          class="float-right"
          @click="openCreateModal"
        >
          <feather-icon icon="PlusIcon" /> Tilføj vejestation
        </b-button>
      </div>
      <page-loader :loading="isLoading">
        <b-table
          :fields="tableFields"
          :items="registrations"
          show-empty
          :empty-text="T('Web.Generic.TableEmpty')"
        >
          <template #cell(pin1)="data">
            <span class="font-weight-bold">{{ data.value }}</span>
          </template>
          <template #cell(pin2)="data">
            <span class="font-weight-bold">{{ data.value }}</span>
          </template>
          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                size="sm"
                variant="flat-danger"
                @click="deleteRegistration(data.item.id)"
                >{{ T("Web.Generic.Remove", "Remove") }}
                <feather-icon icon="TrashIcon" size="16" />
              </b-button>
            </div>
          </template>
        </b-table>
      </page-loader>
    </b-card>
  </b-container>
</template>

<script>
import { BContainer, BCard, BTable, BButton } from "bootstrap-vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ResourceService from "@/services/base/resource.service";
import AddWeightStationRegistrationModal from "./components/AddWeightStationRegistrationModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  components: {
    BTable,
    PageLoader,
    BContainer,
    BCard,
    BButton,
    AddWeightStationRegistrationModal,
    ConfirmModal,
  },
  async created() {
    await this.fetchRegistrations();
  },
  data() {
    return {
      isLoading: true,
      registrations: [],
      tableFields: [
        {
          key: "refId",
          label: "Ref id",
          sortable: true,
        },
        {
          key: "description",
          label: "Beskrivelse",
          sortable: true,
        },
        {
          key: "pin1",
          label: "Pin 1",
          sortable: true,
        },
        {
          key: "pin2",
          label: "Pin 2",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async fetchRegistrations() {
      this.isLoading = true;
      const service = new ResourceService("weight-stations/registrations");
      this.registrations = (await service.all()).data;
      this.isLoading = false;
    },
    async openCreateModal() {
      const result = await this.$refs.AddWeightStationRegistrationModal.open();
      if (result == true) {
        this.fetchRegistrations();
      }
    },
    async deleteRegistration(id) {
      if (!(await this.$refs.simulationConfirmModal.confirm())) return;
      const service = new ResourceService("weight-stations/registrations");
      await service.delete(id);
      await this.fetchRegistrations();
    },
  },
};
</script>,
