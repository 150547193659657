<template>
  <b-modal
    id="booststrapmodal"
    centered
    :visible="visible"
    @hidden="handleModalHidden"
    size="sm"
    title="Opret vejestation"
  >
    <b-form-input
      placeholder="beskrivelse (valgfri)"
      autofocus
      v-model="data.description"
    />
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="float-right" @click="handelSubmit">
          {{ T("Web.Generic.Create") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleModalHidden"
        >
          {{ T("Web.Generic.Cancel  ") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { BModal, BButton, BFormInput } from "bootstrap-vue";
import ResourceService from "@/services/base/resource.service";
import { uuid } from "vue-uuid";

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
  },
  data() {
    return {
      visible: false,
      data: {
        id: null,
        description: null,
      },
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    open() {
      this.visible = true;
      this.data = {
        id: uuid.v4(),
        description: "",
      };
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.visible = false;
    },
    async handelSubmit() {
      const service = new ResourceService("weight-stations/registrations");
      await service.post("", this.data);
      this.visible = false;
      return this.resolvePromise(true);
    },
  },
};
</script>